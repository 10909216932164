import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layouts/MdxPageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "welcome-to-my-corner-of-the-world-wide-web",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#welcome-to-my-corner-of-the-world-wide-web",
        "aria-label": "welcome to my corner of the world wide web permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Welcome to my corner of the World Wide Web`}</h1>
    <p>{`I'm a software engineer by trade and I've created this site as a one-stop shop for information pertaining to my personal and professional lives. Apparently they let anyone put websites online these days, so welcome to mine.`}</p>
    <h3 {...{
      "id": "my-life-right-now",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#my-life-right-now",
        "aria-label": "my life right now permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`My life right now`}</h3>
    <p>{`I'm a Senior Engineer on the `}<a parentName="p" {...{
        "href": "https://docs.stripe.com/libraries#server-side-libraries"
      }}>{`SDKs`}</a>{` team at `}<a parentName="p" {...{
        "href": "https://stripe.com"
      }}>{`Stripe`}</a>{` and work remotely from my home in San Mateo, CA. Before that, I was on the `}<a parentName="p" {...{
        "href": "https://docs.stripe.com/sandboxes"
      }}>{`Sandboxes`}</a>{` team.`}</p>
    <h3 {...{
      "id": "my-experience",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#my-experience",
        "aria-label": "my experience permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`My experience`}</h3>
    <p>{`If you're interested in a curated list of my best projects, `}<a parentName="p" {...{
        "href": "/projects"
      }}>{`look no further`}</a>{`. You can also check out my `}<a parentName="p" {...{
        "href": "https://github.com/xavdid"
      }}>{`GitHub profile`}</a>{`, which is all of the public code I've ever written.`}</p>
    <p>{`My main technical expertise is in the `}<inlineCode parentName="p">{`Python`}</inlineCode>{` and `}<inlineCode parentName="p">{`Typescript`}</inlineCode>{` ecosystems (especially for full stack web development). I've contributed to the largest `}<inlineCode parentName="p">{`Ruby`}</inlineCode>{` codebase in the world and have made some notable open source `}<inlineCode parentName="p">{`Go`}</inlineCode>{` contributions.`}</p>
    <p>{`My primary interest areas are superb developer experiences (especially via the `}<inlineCode parentName="p">{`CLI`}</inlineCode>{`) and crafting web APIs, but I've also enjoyed working with `}<inlineCode parentName="p">{`React.js`}</inlineCode>{`. Mostly, I enjoy doing new things. I have a proven track record of engineering leadership, mentorship, and technical planning + execution.`}</p>
    <p>{`For my formal work history, check out `}<a parentName="p" {...{
        "href": "/resume"
      }}>{`my resume`}</a>{`.`}</p>
    <h3 {...{
      "id": "advent-of-code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#advent-of-code",
        "aria-label": "advent of code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Advent of Code`}</h3>
    <p>{`Since 2020, I've written solutions to all of the `}<a parentName="p" {...{
        "href": "https://adventofcode.com/"
      }}>{`Advent of Code`}</a>{`. They've got their own showcase here: `}<a parentName="p" {...{
        "href": "https://advent-of-code.xavd.id/"
      }}>{`https://advent-of-code.xavd.id/`}</a></p>
    <h3 {...{
      "id": "davidreviews",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#davidreviews",
        "aria-label": "davidreviews permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`david.reviews`}</h3>
    <p>{`I maintain a comprehensive list of everything I play, read, and watch. Partly to source info for my `}<a parentName="p" {...{
        "href": "/blog/tags/yearly-review"
      }}>{`yearly media awards blog post`}</a>{`, but also just because it's fun.`}</p>
    <p>{`You can see what I'm currently enjoying on the homepage of `}<a parentName="p" {...{
        "href": "https://david.reviews/"
      }}>{`david.reviews`}</a>{`. There's also reviews for each category and (on Airtable) my ever-growing "To X" lists:`}</p>
    <ul>
      <li parentName="ul">{`Books`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://david.reviews/books/"
            }}>{`david.reviews/books`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://airtable.com/shrzRTbsZiBhVeugG"
            }}>{`To Read`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Movies`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://david.reviews/movies/"
            }}>{`david.reviews/movies`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://airtable.com/shrqygVZ287Gh2Y09"
            }}>{`To Watch`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Videogames`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://david.reviews/games/"
            }}>{`david.reviews/games`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://airtable.com/shrajGTisjQqBwxLf"
            }}>{`To Play Next`}</a>{` (and `}<a parentName="li" {...{
              "href": "https://airtable.com/shrMB1f0PQcfg1tnM"
            }}>{`after that`}</a>{`)`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      